*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: Moderat;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Moderat-Light'),
    url('https://mindfulchef-fonts-v2.s3.eu-west-2.amazonaws.com/Moderat-Light.woff2')
      format('woff2');
}

@font-face {
  font-family: Moderat;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Moderat-Medium'),
    url('https://mindfulchef-fonts-v2.s3.eu-west-2.amazonaws.com/Moderat-Medium.woff2')
      format('woff2');
}

@font-face {
  font-family: Grenette;
  font-weight: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('grenette-regular-pro'),
    url('https://mindfulchef-fonts-v2.s3.eu-west-2.amazonaws.com/grenette-regular-pro.woff2')
      format('woff2');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
  color: black;
  display: block;
  line-height: 1.4;
  font-family: Moderat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
}

body.scrollLock {
  overflow: hidden;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 500;
}

* + p,
* + ol {
  margin-top: 16px;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.noscript {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  text-align: center;
}

.noscript p {
  position: absolute;
  top: 30%;
  width: 100%;
  padding: 0 16px;
  transform: translateY(-50%);
  font-size: 19px;
  font-weight: 600;
}

.swiper-weekly-recipe-slide {
  width: auto !important;
}

/* classname gets dynamically appended to the body when needing to hide dixa messenger */
body.dixa-hide .dixa-messenger-namespace {
  display: none;
}

/* classname gets dynamically appended to the body when is not an allowed dixa messenger route*/
body.dixa-disabled-route .dixa-messenger-namespace {
  display: none !important; /* using important to make sure it overrides inline styles */
}
